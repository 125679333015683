import React from 'react'
import Card from 'react-bootstrap/Card';


export default function ProductCard() {
    return (
        <div className="container" dataAos="fade-up">

            <div className="section-title">
                <h2>Our Products</h2>
            </div>

            <div className="row no-gutters">

                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/sumalka-Syrup.jpg" />
                            <Card.Body>
                                <Card.Title><h4>SUMALKA SYRUP</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>

                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/sumgold.jpg" />
                            <Card.Body>
                                <Card.Title><h4>SUMGOLD SYRUP</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>
                

                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning' >
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/sumcozyme-syrup.jpg" />
                            <Card.Body>
                                <Card.Title><h4>SUMCOZYME SYRUP</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>

                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/vitsum-syrup.jpg" />
                            <Card.Body>
                                <Card.Title><h4>VITSUM SYRUP</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>

                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/sumco-DHA.jpg" />
                            <Card.Body>
                                <Card.Title><h4>SUMCO- DHA</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>

                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/sumbe-40.jpg" />
                            <Card.Body>
                                <Card.Title><h4>SUMBE-40</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>
                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/sumco-TH4.jpg" />
                            <Card.Body>
                                <Card.Title><h4>SUMCO-TH4</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>
                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/sum-good-OD.jpg" />
                            <Card.Body>
                                <Card.Title><h4>Sum-Good-OD</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>
                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/sumlubri.jpg" />
                            <Card.Body>
                                <Card.Title><h4>Sum Lubri</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>
                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/summer-cal.jpg" />
                            <Card.Body>
                                <Card.Title><h4>Summer-CAL</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>
                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/summer-CV625.jpg" />
                            <Card.Body>
                                <Card.Title><h4>Summer-CV 625</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>
                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/sumpro-NT.jpg" />
                            <Card.Body>
                                <Card.Title><h4>Sumpro-NT</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>
                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/sunbe-DSR.jpg" />
                            <Card.Body>
                                <Card.Title><h4>Sunbe-DSR</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>
                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/sunbe-LSR.jpg" />
                            <Card.Body>
                                <Card.Title><h4>Sunbe-LSR</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>
                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch p-2 m-5 border border-warning'>
                    <Card style={{ width: '25rem', height: 'auto' }}>                       
                            <Card.Img variant="top" src="assets/img/products/sunlife-XT.jpg" />
                            <Card.Body>
                                <Card.Title><h4>Sunlife-XT</h4></Card.Title>
                                <Card.Text>                                 
                                </Card.Text>
                            </Card.Body>                       
                    </Card>
                </div>

               

            </div>
        </div>
    )
}
