import React from 'react'

export default function () {
    return (
        <div class="container">
            <div className="section-title">
                <br></br>
                <h2>List of Products</h2>               
            </div>

            <table class="tableizer-table table table-hover">
                <thead><tr class="tableizer-firstrow"><th>SL. NO. </th><th>PRODUCT NAME</th><th>COMPOSITION</th></tr></thead><tbody>
                    <tr><td>1</td><td>SUM-PLUS </td><td>ACECLOFENAC 100MG + PARACETAMOL 325MG TABLET.</td></tr>
                    <tr><td>2</td><td>VITSUM SYP.</td><td>MULTIVITAMIN MULTIMINRALS AND ANTIOXIDANT+VTAMIN B-COMPLEX WITH LYSINE SYP+LYCOPENE GREEN TEA EXTRACT AND METHYLCOBALAMIN+</td></tr>
                    <tr><td>3</td><td>SUNBE-DSR</td><td>RABEPRAZOLE 20MG + DOMPERIDONE 30MG SR CAPSULE</td></tr>
                    <tr><td>4</td><td>SUNLIFE-XT </td><td>FERROUS ASCORBATE 100 MG + FOLIC ACID 1.5 MG  + ZINC SULPHATE MONOHYDRATE 61.8 MG, SOFT GEL CAPSULE</td></tr>
                    <tr><td>5</td><td>SUMCO-TH4</td><td>ETORICOXIB 60 MG+THIOCOLCHICOSIDE 4MG ( ALU ALU)</td></tr>
                    <tr><td>6</td><td>SUMPAIN Gel</td><td>" DICLOFENAC SODIUM IP 1% W/W,+OLEUM 3%W/w METHYL SALICYLATE IP 10% w/w,+MENTHOL 5%W/w"</td></tr>
                    <tr><td>7</td><td>Sumlubri Eye Drop</td><td> SODIUM CARBOXY METHYL -CELLULOSE Ophthalmic solution ip-0.5%w/v or 1%w/v</td></tr>
                    <tr><td>8</td><td>Sumbe-40 </td><td>Pantaprazole 40mg tablet</td></tr>
                    <tr><td>9</td><td>SUMPRO-M</td><td>PREGABALIN 75MG + METHYCOBALAMIN 750MCG</td></tr>
                    <tr><td>10</td><td>EBER-SUMCO</td><td>Eberconazole 1.0% cream</td></tr>
                    <tr><td>11</td><td>SUM-ITRA-200</td><td>ITRACONAZOLE 200MG CAPSULE</td></tr>
                    <tr><td>12</td><td>TERBINA SUMCO</td><td>TERBINAFINE-250MG</td></tr>
                    <tr><td>13</td><td>SUMCO-DHA</td><td>PROTEIN POWDER WITH  DHA</td></tr>
                    <tr><td>14</td><td>CLEARZINE -10</td><td>FLUNARIZINE 10MG</td></tr>
                    <tr><td>15</td><td>SUM GOOD-OD</td><td>DOXYLLAMINE 20MG+VITAMIN B6+PYRIDOXINE 20MG+FOLIC ACID5MG</td></tr>
                    <tr><td>16</td><td>SUMMER-CV 625/375</td><td>AMOXYCILLIN TRIHYDRATE 500/2500 MG + CLAVULANATE POTASSIUM 125MG</td></tr>
                    <tr><td>17</td><td>SUMGOLD SYP.</td><td>SILYMARINE+L-O,+L-A,+TRICHOLINE CITRATE+B-COMPLEX SUSP.</td></tr>
                    <tr><td>18</td><td>SUMGATI-P EYE DROP</td><td>GATIFLOXACIM+PREDNISOLONE </td></tr>
                    <tr><td>19</td><td>SUMMER-CAL</td><td>CALCIUM CARBONATE 500 MG +  VITAMIN D3 2000 I.U.</td></tr>
                    <tr><td>20</td><td>SUMALKA SYP.</td><td>DISODIUM HYDROGEN CITRATE 1.25/5ML</td></tr>
                    <tr><td>21</td><td>SUMCOZYME SYP.</td><td>FUNGAL DIASTASE & PEPSIN SYRUP</td></tr>
                    <tr><td>22</td><td>C-TO-Z TAB.</td><td>VITAMIN-C+CITAMIN D3+CURCUMA+LONGA EXTRACT WITH ZINC TABLET.</td></tr>
                    <tr><td>23</td><td>SUNBE-LSR</td><td>RABEPRAZOLE 20MG + LEVOSULPIRIDE 75MG SR</td></tr>
                    <tr><td>24</td><td>VITSUM EYE PLUS </td><td>OMEGA3 FATTY ACID+MULTIVITAMIN+LUTEIN+ZEAXANTHIN SOFT GEL.</td></tr>
                </tbody></table>
        </div>
    )
}
