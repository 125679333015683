import React from 'react'

export default function Featured() {
  return (
    <div>
          <section id="featured-services" className="featured-services">
                            <div className="container" data-aos="fade-up">

                                <div className="row">
                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                        <div className="icon-box" data-aos="fade-up" data-aos-delay="100">
                                            <div className="icon"><i className="fas fa-heartbeat"></i></div>
                                            <h4 className="title"><a href="">Cardiac Care</a></h4>
                                            <p className="description">We manufacture state-of-the-art cardiac care medicine to support heart health and to ensure optimal heart health.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                        <div className="icon-box" data-aos="fade-up" data-aos-delay="200">
                                            <div className="icon"><i className="fas fa-pills"></i></div>
                                            <h4 className="title"><a href="">General Medicines</a></h4>
                                            <p className="description">We specialize in manufacturing high-quality regular medicines to ensure effective and reliable healthcare solutions.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                        <div className="icon-box" data-aos="fade-up" data-aos-delay="300">
                                            <div className="icon"><i className="fas fa-thermometer"></i></div>
                                            <h4 className="title"><a href="">Medical Devices</a></h4>
                                            <p className="description">We manufacture innovative medical devices designed to enhance patient care and improve clinical outcomes.</p>
                                        </div>
                                    </div>

                                    <div className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
                                        <div className="icon-box" data-aos="fade-up" data-aos-delay="400">
                                            <div className="icon"><i className="fas fa-dna"></i></div>
                                            <h4 className="title"><a href="">Critical Care</a></h4>
                                            <p className="description">We manufacture advanced critical care medicines to support and enhance patient recovery in intensive care settings.</p>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </section>
    </div>
  )
}
