import React from 'react'
import Card from 'react-bootstrap/Card';

export default function () {
    return (
        <div className="container" dataAos="fade-up">

            <div className="section-title">
                <h2>Product Visulate</h2>
            </div>

            <div className="row no-gutters">

                <div className='col-lg-12 col-md-9 d-md-flex align-items-md-stretch'>
                    <Card style={{ width: 'auto', height: 'auto' }}>
                        <a target="_blank" href="assets/img/products.jpg">
                            <Card.Img variant="top" src="assets/img/products.jpg" />                            
                        </a>
                    </Card>
                </div>
            </div>
        </div>

    )
}
