import React from 'react'

export default function Cta() {
    return (
        <div>
            <section id="cta" className="cta">
                <div className="container" data-aos="zoom-in">

                    <div className="text-center">
                        <h3>In an emergency? Need help now?</h3>
                        <p> We manufacture emergency medical solutions designed to save lives with immediate, reliable support. Our emergency medical products provide critical care when every second counts, ensuring swift and effective assistance.</p>
                        <a className="cta-btn scrollto" href="#appointment"> Order Now</a>
                    </div>

                </div>
            </section>
        </div>
    )
}
