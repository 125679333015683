import React from 'react'
import ProductList from '../components/ProductList'
import Footer from '../components/Footer'
import Topbar from '../components/Topbar'
import Nav from '../components/Navbar'
import Breadcrumbs from '../components/Breadcrumbs'
import Contact from '../components/Contact'

export default function AllProducts() {
    return (
        <>
            <Topbar />
            <Nav />
            <Breadcrumbs pageName="List of Products" pageTitle="Products : Sun Summer Pharmaceuticals Pvt. Ltd" />
            <ProductList />
            <Contact />
            <Footer />
        </>
    )
}
