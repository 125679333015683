import React from 'react'
import {Link } from 'react-router-dom'

export default function Contact() {
    return (
        <section id="contact" className="contact">
            <div className="container">

                <div className="section-title">
                    <h2>Contact</h2>
                    <p>Let's connect! Contact our team for inquiries, partnerships, or general information. We're eager to hear from you. Contact us for any inquiries or feedback.</p>
                </div>

            </div>

            {/* <div>
                <iframe style={border:0; width: 100%; height: 350px} src={'https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12097.433213460943!2d-74.0062269!3d40.7101282!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xb89d1fe6bc499443!2sDowntown+Conference+Center!5e0!3m2!1smk!2sbg!4v1539943755621'} frameborder="0" allowfullscreen></iframe>
            </div> */}

            <div className="container">

                <div className="row mt-5">

                    <div className="col-lg-6">

                        <div className="row">
                            <div className="col-md-12">
                                <div className="info-box">
                                    <i className="bx bx-map"></i>
                                    <h3>Our Address</h3>
                                    <p>SUBHASHGRAM, SOUTH 24
                                        PARGANA, West Bengal-700147</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="info-box mt-4">
                                    <i className="bx bx-envelope"></i>
                                    <h3>Email Us</h3>
                                    <p>info@esunsummerpharmaceuticals.com<br />sunsummerpharmaceuticals@gmail.com</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="info-box mt-4">
                                    <i className="bx bx-phone-call"></i>
                                    <h3>Call Us</h3>
                                    <p>+91 9434986041

                                    </p>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="col-lg-6">
                        <form action="forms/contact.php" method="post" role="form" className="php-email-form">
                            <div className="row">
                                <div className="col form-group">
                                    <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                                </div>
                                <div className="col form-group mt-3">
                                    <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                                </div>
                            </div>
                            <div className="form-group mt-3">
                                <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required />
                            </div>
                            <div className="form-group mt-3">
                                <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                            </div>
                            <div className="my-3">
                                <div className="loading">Loading</div>
                                <div className="error-message"></div>
                                <div className="sent-message">Your message has been sent. Thank you!</div>
                            </div>
                            <div className="text-center"><button type="submit">Send Message</button></div>
                        </form>
                    </div>

                </div>

            </div>
        </section>
    )
}
