import React from 'react'

export default function () {
    return (
        <section id="features" className="features">
            <div className="container" data-aos="fade-up">

                <div className="row">
                    <div className="col-lg-6 order-2 order-lg-1" data-aos="fade-right">
                        <div className="icon-box mt-5 mt-lg-0">
                            <i className="bx bx-receipt"></i>
                            <h4>Well Documentation</h4>
                            <p>Our operations are meticulously documented to ensure clarity, consistency, and excellence in every step.</p>
                        </div>
                        <div className="icon-box mt-5">
                            <i className="bx bx-cube-alt"></i>
                            <h4>innovative Products</h4>
                            <p>Our innovative products are designed to meet tomorrow's challenges today, delivering cutting-edge solutions.
                            </p>
                        </div>
                        <div className="icon-box mt-5">
                            <i className="bx bx-plus"></i>
                            <h4>Stocks</h4>
                            <p>We maintain a vast inventory of products to ensure we meet all your needs with our extensive stock.</p>
                        </div>
                        <div className="icon-box mt-5">
                            <i className="bx bx-shield"></i>
                            <h4>Protection </h4>
                            <p>Our lineup includes advanced medicines that provide both protection and security, ensuring optimal care for patients.</p>
                        </div>
                    </div>
                    <div className="image col-lg-6 order-1 order-lg-2" style={{backgroundImage: 'url("assets/img/features.jpg")'}} data-aos="zoom-in"></div>
                </div>

            </div>
        </section>
    )
}
