import React from 'react'

export default function About1() {
    return (
        <section id="about" className="about">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>About Us</h2>
                    <p>At Sun Summer Pharmaceuticals Pvt. Ltd., we have a rich history of serving the healthcare industry with integrity and passion. We are driven by a vision to provide innovative healthcare solutions that meet the evolving needs of patients and healthcare professionals worldwide. Our team of experienced professionals, including scientists, researchers, and industry experts, work tirelessly to develop safe, effective, and affordable medicines.</p>
                </div>

                <div className="row">
                    <div className="col-lg-6" data-aos="fade-right">
                        <img src="assets/img/gallery/gallery-6.jpg" className="img-fluid" alt="" />
                    </div>
                    <div className="col-lg-6 pt-4 pt-lg-0 content" data-aos="fade-left">
                        <h3>Our Mission & Core Values</h3>
                        <p className="fst-italic">
                            At Sun Summer Pharmaceuticals Pvt. Ltd., we hold the following values at the core of our operations:
                        </p>
                        <ul>
                            <li><i className="bi bi-check-circle"></i> Our Mission:
                                Our mission is to enhance lives by providing exceptional healthcare solutions. We aim to discover, develop, and deliver high-quality pharmaceutical products that contribute to the well-being of individuals and communities. By advancing medical knowledge, supporting healthcare education, and promoting accessibility, we strive to create a healthier and brighter future for all.
                            </li>
                            <li><i className="bi bi-check-circle"></i> Our Values:
                                Integrity: We conduct our business with the utmost integrity and ethical standards. Transparency, honesty, and accountability are at the core of everything we do. We believe in building trust through responsible practices and fostering strong relationships with our stake holders.
                            </li>
                        </ul>

                    </div>
                </div>

            </div>
        </section>
    )
}
