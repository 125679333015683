
import Footer from '../components/Footer'
import Topbar from '../components/Topbar'
import Contact from '../components/Contact'
import Nav from '../components/Navbar'

import AboutSection from '../components/About1'
import Counter from '../components/Counts'

import Breadcrumbs from '../components/Breadcrumbs'
import DocCard from '../components/DocCard'



export default function AboutPage() {
  return (
    <>
      <Topbar />
      <Nav />
      <Breadcrumbs pageName="About Us" pageTitle="About : Sun Summer Pharmaceuticals Pvt. Ltd" />
      <AboutSection />
      <DocCard/>
      <Counter />
      <Contact />   

      <Footer />
    </>
  )
}
