import React from 'react'
import {Link } from 'react-router-dom'

export default function footer() {
    return (
        <>
            <footer id="footer">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">

                            <div className="col-lg-3 col-md-6">
                                <div className="footer-info">
                                    <h3>Sun Summer Pharmaceuticals Pvt. Ltd.</h3>

                                    <div className="social-links mt-3">
                                        <Link to="#" className="twitter"><i className="bx bxl-twitter"></i></Link>
                                        <Link to="#" className="facebook"><i className="bx bxl-facebook"></i></Link>
                                        <Link to="#" className="instagram"><i className="bx bxl-instagram"></i></Link>
                                        <Link to="#" className="google-plus"><i className="bx bxl-skype"></i></Link>
                                        <Link to="#" className="linkedin"><i className="bx bxl-linkedin"></i></Link>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-md-6 footer-links">
                                <h4>Useful Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/">Home</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/about">About us</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/visulate">Visulate</Link></li>
                                  
                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-6 footer-links">
                                <h4>Quick Links</h4>
                                <ul>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/product-card">All Products</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/product-list">Product List</Link></li>
                                    <li><i className="bx bx-chevron-right"></i> <Link to="/contact">Contact Us</Link></li>
                                  

                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-6 footer-newsletter">
                                <h4>Our Newsletter</h4>
                                <p>Subscribe to our newsletter and receive exciting updates directly in your inbox. </p>
                                <form action="" method="post">
                                    <input type="email" name="email" />
                                    <input type="submit" value="Subscribe" />
                                </form>

                            </div>

                        </div>
                    </div>
                </div>

                <div className="container">
                    <div className="copyright">
                        &copy; Copyright <strong><span>Sun Summer Pharmaceuticals</span></strong>. All Rights Reserved 
                         <span> Designed by :Tarak Siddhanta </span>
                    </div>
                    
                </div>
            </footer>
           
        </>
    )
}
