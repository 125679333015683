import React from 'react'
import Footer from '../components/Footer'
import Topbar from '../components/Topbar'
import Nav from '../components/Navbar'
import Breadcrumbs from '../components/Breadcrumbs'
import Contact from '../components/Contact'
import Visulate from '../components/Visulate'


export default function VisulatePage() {
    return (
        <>
            <Topbar />
            <Nav />
            <Breadcrumbs pageName="Product Visulate" pageTitle="Product Visulate : Sun Summer Pharmaceuticals Pvt. Ltd" />
            <Visulate/>
            <Contact />
            <Footer />
        </>
    )
}
