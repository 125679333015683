import React from 'react'

export default function Counts() {
    return (
        <section id="counts" className="counts">
            <div className="container" dataAos="fade-up">

                <div className="row no-gutters">

                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                            <i className="fas fa-user-md"></i>
                            <span className="purecounter"> 500 +</span>
                            <p><strong>Doctors</strong> Recomended Our Medicines</p>
                            <a href="#">Find out more &raquo;</a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                            <i className="far fa-hospital"></i>
                            <span className="purecounter"> 100 +</span>
                            <p><strong>Hospitals</strong> Recomended Our Medicines</p>
                            <a href="#">Find out more &raquo;</a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                            <i className="fas fa-flask"></i>
                            <span className="purecounter"> 35 +</span>
                            <p><strong>Medical </strong> Products & Medicines</p>
                            <a href="#">Find out more &raquo;</a>
                        </div>
                    </div>

                    <div className="col-lg-3 col-md-6 d-md-flex align-items-md-stretch">
                        <div className="count-box">
                            <i className="fas fa-award"></i>
                            <span className="purecounter"> 200 +</span>
                            <p><strong>Staffs </strong> & Representatives till now.</p>
                            <a href="#">Find out more &raquo;</a>
                        </div>
                    </div>

                </div>

            </div>
        </section>
    )
}
