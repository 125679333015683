import React from 'react'
import ProductCard from '../components/ProductCard'
import Footer from '../components/Footer'
import Topbar from '../components/Topbar'
import Nav from '../components/Navbar'
import Breadcrumbs from '../components/Breadcrumbs'
import Contact from '../components/Contact'

export default function ProductPage() {
  return (
    <>
    <Topbar />
    <Nav />
    <Breadcrumbs pageName="Our Products" pageTitle="Products : Sun Summer Pharmaceuticals Pvt. Ltd" />
    <ProductCard />
    <Contact />
    <Footer />
  </>
  )
}
