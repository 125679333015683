import React from 'react'

export default function topbar() {
    return (
        <div>
            
            <div id="topbar" className="d-flex align-items-center fixed-top">
                <div className="container d-flex align-items-center justify-content-center justify-content-md-between">
                    <div className="align-items-center d-none d-md-flex">
                        <i className="bi bi-clock"/> Monday - Saturday, 8AM to 10PM
                    </div>
                    <div className="d-flex align-items-center">
                        <i className="bi bi-phone"/>Call us now +91 9434986041
                    </div>
                </div>
            </div>
        </div>
    )
}
