import React from 'react'
import { Link } from 'react-router-dom'

export default function Navbar() {
    return (
        <div><header id="header" className="fixed-top">
            <div className="container d-flex align-items-center justify-content-md-between">

                <a href="index.html" className="logo "><img src="assets/img/bg-white.png" alt="Sun Summer Pharmaceuticals" /></a>
               

                <nav id="navbar" className="navbar order-last order-lg-0">
                    <ul>
                        <li><Link className="nav-link scrollto " to="/">Home</Link></li>
                        <li><Link className="nav-link scrollto" to="/about">About</Link></li>

                        
                        <li><Link className="nav-link scrollto " to="/">Career</Link></li>
                        <li className="dropdown"><a href="#"><span>Products</span> <i className="bi bi-chevron-down"></i></a>
                            <ul>
                                <li><Link className="nav-link scrollto " to="/product-list">All Products</Link></li>

                                <li><Link className="nav-link scrollto " to="/visulate">Product Visulate</Link></li>
                                <li><Link className="nav-link scrollto " to="/product-card">Product Card</Link></li>
                            </ul>
                        </li>
                        <li><Link className="nav-link scrollto" to="/contact">Contact</Link></li>
                    </ul>
                    <i className="bi bi-list mobile-nav-toggle"></i>
                </nav>


                <a href="assets/img/SUN SUMMER.pdf" target="_blank" className="appointment-btn pull-right"><span className="d-none d-md-inline"></span> Download Catalogue</a>

            </div>
        </header>
        <br/>
        </div>
    )
}
