import {React , useState} from 'react'
import {Link } from 'react-router-dom'


export default function Breadcrumbs(props) {
    //const[pageName, SetPageName]= useState('Home')
    return (
        <>
            <section className="breadcrumbs">
                <div className="container">
                    <div className="d-flex justify-content-between align-items-center">
                        <p style={{color:'oragne'}}><strong>{props.pageTitle}</strong></p>
                        <ol>
                            <li><Link to="/">Home</Link></li>
                            <li>{props.pageName}</li>
                        </ol>
                    </div>
                </div>
            </section>
        </>
    )
}
