import React from 'react'
import Carousel from 'react-bootstrap/Carousel';
//import slider1 from "../../public/assets/img/slide/slider-1.jpg"

export default function CouraselFull() {
  return (
    <>
    <br/><br/><br/>
      <Carousel data-bs-theme="dark">
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="assets/img/slide/slider-3n.jpg"
            alt="First slide"
          />
          {/* <Carousel.Caption>
              <h5>First slide label</h5>
              <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
            </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="assets/img/slide/slider-1.jpg"
            alt="Second slide"
          />
          {/* <Carousel.Caption>
              <h5>Second slide label</h5>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
            </Carousel.Caption> */}
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="assets/img/slide/slider-3.png"
            alt="Third slide"
          />
          {/* <Carousel.Caption>
              <h5>Third slide label</h5>
              <p>
                Praesent commodo cursus magna, vel scelerisque nisl consectetur.
              </p>
            </Carousel.Caption> */}
        </Carousel.Item>
      </Carousel>
    </>
  )
}
