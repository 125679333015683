import react from 'react'
import Footer from '../components/Footer'
import Topbar from '../components/Topbar'
import Contact from '../components/Contact'
import Nav from '../components/Navbar'
import CouraselFull from '../components/CouraselFull'
import Feature from '../components/Featured'
import CTA from '../components/Cta'
import About from '../components/About1'
import Counter from '../components/Counts'

import Appo from '../components/Appointment'

import Service from '../components/Services'
import Testimonial from '../components/Testimonials'
import Feat from '../components/Features'

export default function HomePage() {
    return (
        <>
            <Topbar />
            <Nav />
            <CouraselFull/>
            <Feature />
            <CTA />
            <About />
            <Counter />
            <Contact />
            <Appo />
           
            <Service />
            <Testimonial />
            <Feat />
            <Footer />
        </>
    )
}


