
import {Routes, Route, BrowserRouter} from 'react-router-dom'
import Home from './pages/HomePage'
import About from './pages/AboutPage'
import Contact from './pages/ContactPage'
import AllProducts from './pages/AllProducts'
import VisulatePage from './pages/VisulatePage'
import ProductPage from './pages/ProductPage'

function App() {
  return (
    <>
    <BrowserRouter>
    <Routes>
      <Route path='/' element={<Home/>} />
      <Route path='/about' element={<About/>} />
      <Route path='/contact' element={<Contact/>} />
      <Route path='/product-list' element={<AllProducts/>} />
      <Route path='/visulate' element={<VisulatePage/>} />
      <Route path='/product-card' element={<ProductPage/>} />
    </Routes>
    </BrowserRouter>

    </>
  );
}

export default App;
