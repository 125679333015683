import React from 'react'

export default function () {
  return (
    <section id="testimonials" className="testimonials">
    <div className="container" data-aos="fade-up">

        <div className="section-title">
            <h2>Testimonials</h2>
            <p>What Others says about us .. </p>
        </div>

        <div className="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
            <div className="swiper-wrapper">

                <div className="swiper-slide">
                    <div className="testimonial-item">
                        <p>
                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                            "Sun Summer Pharmaceuticals has been instrumental in providing quality medications that have significantly improved my health. Their diabetic care medicines have helped me manage my condition effectively, and I'm grateful for their commitment to patient well-being."
                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                        <img src="assets/img/testimonials/testimonials-1.jpg" className="testimonial-img" alt=""/>
                            <h3>Saul Goodman</h3>
                            <h4>Businessman</h4>
                    </div>
                </div>
               
                <div className="swiper-slide">
                    <div className="testimonial-item">
                        <p>
                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                            "I've been impressed with the orthopedic medicines offered by Sun Summer Pharmaceuticals. Their products have played a crucial role in relieving my joint pain and supporting my mobility. I highly recommend their orthopedic solutions for anyone seeking effective relief."
                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                        <img src="assets/img/testimonials/testimonials-2.jpg" className="testimonial-img" alt=""/>
                            <h3>Sara Wilsson</h3>
                            <h4>Designer</h4>
                    </div>
                </div>
               
                <div className="swiper-slide">
                    <div className="testimonial-item">
                        <p>
                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                            "Sun Summer Pharmaceuticals' genetic medicines have truly transformed my approach to personalized healthcare. With their advanced treatments tailored to my genetic profile, I feel confident that I'm receiving the most effective care possible."
                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                        <img src="assets/img/testimonials/testimonials-3.jpg" className="testimonial-img" alt=""/>
                            <h3>Jena Karlis</h3>
                            <h4>Store Owner</h4>
                    </div>
                </div>
               

                <div className="swiper-slide">
                    <div className="testimonial-item">
                        <p>
                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                            "As a healthcare professional, I've had the pleasure of working with Sun Summer Pharmaceuticals for several years now. Their commitment to quality and innovation is truly commendable. The range of pharmaceutical products they offer has been instrumental in providing effective treatments for my patients. I highly recommend Sun Summer Pharmaceuticals to anyone seeking reliable and high-quality pharmaceutical solutions."
                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                        <img src="assets/img/testimonials/testimonials-4.jpg" className="testimonial-img" alt=""/>
                            <h3>Dr. Matt Brandon</h3>
                            <h4>Doctor</h4>
                    </div>
                </div>
               
                <div className="swiper-slide">
                    <div className="testimonial-item">
                        <p>
                            <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                            "I've been a loyal customer of Sun Summer Pharmaceuticals for over a decade, and I continue to be impressed by their dedication to excellence. Their diabetic care medicines have significantly improved my quality of life, allowing me to manage my condition with ease. Their customer service is exceptional, and I always feel valued as a client. Thank you, Sun Summer Pharmaceuticals, for your outstanding products and unwavering support."
                            <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                        </p>
                        <img src="assets/img/testimonials/testimonials-5.jpg" className="testimonial-img" alt=""/>
                            <h3>John Larson</h3>
                            <h4>Diabetic Patient</h4>
                    </div>
                </div>
           

            </div>
            <div className="swiper-pagination"></div>
        </div>

    </div>
</section>
  )
}
