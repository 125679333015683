import React from 'react'

export default function
    () {
    return (
        <section id="services" className="services services">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Categories</h2>
                    <p>We provide a comprehensive selection of medicines across various categories to cater to diverse healthcare needs. Our inventory includes a wide range of medicine categories, ensuring we have solutions for every medical requirement.</p>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="100">
                        <div className="icon"><i className="fas fa-heartbeat"></i></div>
                        <h4 className="title"><a href="">Cardiac Care</a></h4>
                        <p className="description">We manufacture state-of-the-art cardiac care medicine to support heart health and to ensure optimal heart health.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="200">
                        <div className="icon"><i className="fas fa-pills"></i></div>
                        <h4 className="title"><a href="">General Medicines</a></h4>
                        <p className="description">We specialize in manufacturing high-quality regular medicines to ensure effective and reliable healthcare solutions.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300">
                        <div className="icon"><i className="fas fa-hospital-user"></i></div>
                        <h4 className="title"><a href="">Critical Care</a></h4>
                        <p className="description">We manufacture advanced critical care medicines to support and enhance patient recovery in intensive care settings.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="100">
                        <div className="icon"><i className="fas fa-dna"></i></div>
                        <h4 className="title"><a href="">Genetic Medicines</a></h4>
                        <p className="description">Our genetic medicines are designed to target specific genetic mutations, paving the way for personalized and effective treatments.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="200">
                        <div className="icon"><i className="fas fa-wheelchair"></i></div>
                        <h4 className="title"><a href="">Orthopedic Medicines</a></h4>
                        <p className="description">Our orthopedic medicines that target joint and bone health, supporting mobility and overall orthopedic wellness.</p>
                    </div>
                    <div className="col-lg-4 col-md-6 icon-box" data-aos="zoom-in" data-aos-delay="300">
                        <div className="icon"><i className="fas fa-notes-medical"></i></div>
                        <h4 className="title"><a href="">Diabetic Care</a></h4>
                        <p className="description">Our diabetic care medicines are crafted to effectively manage blood sugar levels and promote overall health for individuals with diabetes.</p>
                    </div>
                </div>

            </div>
        </section>
    )
}
