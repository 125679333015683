import React from 'react'
import Card from 'react-bootstrap/Card';

export default function DocCard() {
    return (
        <div className="container" dataAos="fade-up">

            <div className="section-title">
                <h2>Our Legal Documents</h2>               
            </div>
            
            <div className="row no-gutters">

                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch'>
                    <Card style={{ width: '15rem', height: 'auto' }}>
                        <a target="_blank" href="assets/img/GST.pdf">
                            <Card.Img variant="top" src="assets/img/doc-icon.png" />
                            <Card.Body>
                                <Card.Title><h4>GST Documents</h4></Card.Title>
                                <Card.Text>
                                    {/* <Button variant="primary">Go somewhere</Button> */}
                                </Card.Text>
                            </Card.Body>
                        </a>
                    </Card>
                </div>

                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch'>
                    <Card style={{ width: '15rem', height: 'auto' }}>
                        <a target="_blank" href="assets/img/fssai-LICENSE.pdf">
                            <Card.Img variant="top" src="assets/img/doc-icon.png" />
                            <Card.Body>
                                <Card.Title><h4>FSSAI Licence</h4></Card.Title>
                                <Card.Text>
                                    {/* <Button variant="primary">Go somewhere</Button> */}
                                </Card.Text>
                            </Card.Body>
                        </a>
                    </Card>
                </div>

                <div className='col-lg-3 col-md-6 d-md-flex align-items-md-stretch'>
                    <Card style={{ width: '15rem', height: 'auto' }}>
                        <a target="_blank" href="assets/img/PAN.jpg">
                            <Card.Img variant="top" src="assets/img/doc-icon.png" />
                            <Card.Body>
                                <Card.Title><h4>Company PAN</h4></Card.Title>
                                <Card.Text>
                                    {/* <Button variant="primary">Go somewhere</Button> */}
                                </Card.Text>
                            </Card.Body>
                        </a>
                    </Card>
                </div>

            </div>
        </div>
    )
}
