import React from 'react'

export default function Appointment() {
    return (
        <section id="appointment" className="appointment section-bg">
            <div className="container" data-aos="fade-up">

                <div className="section-title">
                    <h2>Make an Enquiry</h2>
                    <p>Whether you are a Patient, healthcare professional, researcher, or industry partner, we invite you to reach out and explore potential collaborations or opportunities with us.</p>
                </div>

                <form action="forms/appointment.php" method="post" role="form" className="php-email-form" data-aos="fade-up" data-aos-delay="100">
                    <div className="row">
                        <div className="col-md-4 form-group">
                            <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required />
                        </div>
                        <div className="col-md-4 form-group mt-3 mt-md-0">
                            <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required />
                        </div>
                        <div className="col-md-4 form-group mt-3 mt-md-0">
                            <input type="tel" className="form-control" name="phone" id="phone" placeholder="Your Phone" required />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-4 form-group mt-3">
                            <input type="date" name="date" className="form-control datepicker" id="date" placeholder="Appointment Date" required />
                        </div>
                        <div className="col-md-4 form-group mt-3">
                            <select name="department" id="department" className="form-select">
                                <option value="">Select Department</option>
                                <option value="Department 1">Sales</option>
                                <option value="Department 2">Support</option>
                                <option value="Department 3">Operation</option>
                                <option value="Department 3">Management</option>
                                <option value="Department 3">Others</option>
                            </select>
                        </div>
                        <div className="col-md-4 form-group mt-3">
                            <input type="text" name="subject" className="form-control" id="subject" placeholder="Your Subject" required />
                        </div>
                    </div>

                    <div className="form-group mt-3">
                        <textarea className="form-control" name="message" rows="5" placeholder="Message (Optional)"></textarea>
                    </div>
                    <div className="my-3">
                        <div className="loading">Loading</div>
                        <div className="error-message"></div>
                        <div className="sent-message">Your appointment request has been sent successfully. Thank you!</div>
                    </div>
                    <div className="text-center"><button type="submit">Make an Appointment</button></div>
                </form>

            </div>
        </section>
    )
}
