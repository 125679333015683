import React from 'react'

import Footer from '../components/Footer'
import Topbar from '../components/Topbar'
import Contact from '../components/Contact'
import Navbar from '../components/Navbar'
import Breadcrumbs from '../components/Breadcrumbs'




export default function ContactPage() {
  return (
    <>
      <Topbar />
      <Navbar />
      <Breadcrumbs pageName="Contact Us" pageTitle="Contact : Sun Summer Pharmaceuticals Pvt. Ltd" />
      <Contact />
      <Footer />



    </>
  )
}
